<template>
    <div class="title">
        <textarea
        ref="titleText"
        class="input-text"
        type="text"
        id="text"
        name="text"
        v-model="title"
        placeholder="Enter Title here"
        rows="1"
      />
      <p v-if="err.title" class="error">{{err.title}}</p>
    </div>
  <div class="description">
    <bubble-menu :editor="editor" v-if="editor">
      <div class="bubble-menu" ref="bubble-menu">
        <div v-if="showOptions" class="options">
          <i
            class="icon icon-bold_B"
            @click="editor.chain().focus().toggleBold().run()"
            :class="{ 'is-active': editor.isActive('bold') }"
          >
          </i>
          <i
            class="icon icon-italic_I"
            @click="editor.chain().focus().toggleItalic().run()"
            :class="{ 'is-active': editor.isActive('italic') }"
          >
          </i>
          <i
            class="icon icon-clip"
            @click="showInput()"
            :class="{ 'is-active': editor.isActive('link') }"
          >
          </i>
          <i
            class="icon icon-text_T"
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          >
          </i>
          <i
            class="icon icon-double_quotes"
            @click="editor.chain().focus().toggleBlockquote().run()"
            :class="{ 'is-active': editor.isActive('blockquote') }"
          >
          </i>
          <img
            class="icon icon-add-reference"
            src="../../../../assets/icons/add-reference.svg"
            @click="showAddReferenceBubble"
          />
        </div>
        <div v-else-if="visibleInput" class="input-url">
          <input
            v-model="url"
            type="url"
            name=""
            id="url"
            ref="url"
            :autofocus="autofocus"
            @keyup.enter="setLink()"
          />
        </div>
        <div v-else-if="referenceInputVisible" class="reference">
          <add-reference-editor
            @add-reference="addReference"
          ></add-reference-editor>
        </div>
      </div>
    </bubble-menu>
    <editor-content :editor="editor" @click="closeBubbleMenu"> </editor-content>
  </div>
  <p v-if="err.content" class="error">{{err.content}}</p>
</template>
<script>
import AddReferenceEditor from "./Addreference.vue";
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-3";
import { generateHTML } from "@tiptap/html";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Blockquote from "@tiptap/extension-blockquote";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import ExternalVideo from "./iframe.js";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import lowlight from "lowlight";
import Superscript from "@tiptap/extension-superscript";
export default {
  components: {
    EditorContent,
    BubbleMenu,
    AddReferenceEditor,
  },

  data() {
    return {
      editor: null,
      showOptions: true,
      visibleInput: false,
      url: null,
      html: "",
      referenceInputVisible: false,
      savedreferences: [],
      articleReferenceIds: [],
      shouldAddReference: false,
      err: {},
      urlErr: "",
      showMessageModal: false,
      message: {}
    };
  },
  computed: {
    title: {
      // setter
      set(text){
        this.$store.dispatch("editor/setTitle", text);
      },
      // getter
      get() {
        return this.$store.getters["editor/getArticleTitle"];
      }
    },
    content(){
      return this.$store.getters["editor/getArticleBody"].articleHTML;
    }
  },
   mounted() {
        this.emitter.on('publishArticle', (err)=>{
          this.publishArticle(err);
        });
        if(this.content!==""){
        // this.html = generateHTML(this.content, [
        //   StarterKit,
        //   Blockquote,
        //   TextStyle,
        //   Image,
        //   ExternalVideo,
        //   HorizontalRule,
        //   CodeBlockLowlight.configure({
        //     lowlight,
        //   }),
        //   Link.configure({
        //     openOnClick: false,
        //   }),
        //   Placeholder.configure({
        //     placeholder: 'Write it out',
        //   }),
        // ]);
        }
        this.editor = new Editor({
        extensions: [
          StarterKit,
          Blockquote,
          TextStyle,
          Image,
          ExternalVideo,
          Superscript.configure({
            HTMLAttributes: {
              class: "reference",
            },
          }),
          HorizontalRule.configure({
          HTMLAttributes: {
            class: "editor-hr",
          },
          }),
          CodeBlockLowlight.configure({
            lowlight,
          }),
          Link.configure({
            openOnClick: false,
          }),
          Placeholder.configure({
            placeholder: 'Write it out',
          }),
        ],
        onUpdate: ({ editor }) => {
          this.update();
          const contentContext = {
            articleBodyTEXT: this.editor.getText(),
            articleBodyHTML: this.editor.getHTML()
          }
          this.$store.dispatch("editor/setArticleBody", contentContext);
        },
      })
      this.editor.commands.setContent(this.$store.getters["editor/getArticleBody"].articleBodyHTML);
 },
  beforeUnmount() {
    this.$store.dispatch('editor/setTitle', this.title);
    const contentContext = {
      articleBodyTEXT: this.editor.getText(),
      articleBodyHTML: this.editor.getHTML()
    }
    this.$store.dispatch('editor/setArticleBody', contentContext);
  },
  watch: {
    showOptions(val) {
      if (!val && !this.visibleInput) {
        this.$refs["bubble-menu"].classList.add("light-bg");
      } else {
        this.$refs["bubble-menu"].classList.remove("light-bg");
      }
    },
  },
  methods: {
    update() {
      this.html = this.editor.getHTML();
      this.savedreferences = this.$store.getters["editor/getAllReferences"];
      this.getReferenceIdsFromEditor(this.html);
      let comparewith = this.articleReferenceIds.length===1 ? (this.savedreferences.length>1 ? 1:this.articleReferenceIds.length-1 ): this.articleReferenceIds.length-1;
      for (let i = 0; i < comparewith; i++) {
        if (this.articleReferenceIds[0]===1 && this.articleReferenceIds[i] + 1 === this.articleReferenceIds[i + 1] && 
        Math.max(...this.articleReferenceIds)===this.savedreferences[this.savedreferences.length-1].id) {
          continue;
        } else {
            if(this.articleReferenceIds.length<this.savedreferences.length){
              this.removeReference(i);
            } else if(this.articleReferenceIds[i]<this.articleReferenceIds[i+1]){
              this.addReferenceAtAnyPosition(i);
            } else{
              this.addReferenceAtFirstPosition();
            } 
          this.editor.commands.setContent(this.html);
          this.savedreferences.sort((a,b)=>{ return a.id - b.id;})
          this.$store.dispatch('editor/setAllReferences', this.savedreferences);
          break;
        }
      } 
    },
    getReferenceIdsFromEditor(){
      this.articleReferenceIds= [];
      let totalreferences = (this.html.match(/<a target="_self" rel="noopener noreferrer nofollow" href="#/g) || []).length;
      let at = 0;
      for (let i = 0; i < totalreferences; i++) {
        let startindex = this.html.indexOf(`<a target="_self" rel="noopener noreferrer nofollow" href="#`,at);
        let endindex = this.html.indexOf(`"><sup class="reference">`,startindex);
        let id = parseInt(this.html.slice(startindex + 60, endindex));
        this.articleReferenceIds.push(id);
        at = startindex + 60;
      }
     } ,
     removeReference(i){
       let removeid =this.articleReferenceIds[0]===1?(this.articleReferenceIds[this.articleReferenceIds.length-1]!==this.savedreferences[this.savedreferences.length-1].id)?this.savedreferences[this.savedreferences.length-1].id:
                        (this.articleReferenceIds[i+1]-1): 1;
        let initialize =   this.articleReferenceIds[0]===1?this.articleReferenceIds.indexOf(this.articleReferenceIds[i + 1]): 0;
        for(let j=initialize; j<this.articleReferenceIds.length; j++){
          if(j==this.savedreferences.indexOf(this.savedreferences[initialize])){
            this.savedreferences = this.savedreferences.filter((val)=>{
              return val.id!==removeid;
            })
            }
            if(this.articleReferenceIds[j]!==1){
              if(this.articleReferenceIds[j]>removeid){
            this.html = this.html.replace(`<a target="_self" rel="noopener noreferrer nofollow" href="#${this.articleReferenceIds[j]}"><sup class="reference">[${this.articleReferenceIds[j]}]</sup>`,
                  `<a target="_self" rel="noopener noreferrer nofollow" href="#${this.articleReferenceIds[j]-1}"><sup class="reference">[${this.articleReferenceIds[j]-1}]</sup>`);
              }            
            for(let k=0; k<this.savedreferences.length; k++){
              if(this.savedreferences[k].id===this.articleReferenceIds[j] && this.savedreferences[k].id>removeid ){
                this.savedreferences[k].id = this.savedreferences[k].id-1;
                break;
              }
            }
          }
          } 
     },
     addReferenceAtAnyPosition(i){
       for (let j = this.articleReferenceIds[i];j < this.articleReferenceIds[i + 1];j++ ) {
          if (j == this.articleReferenceIds[i]) {
            this.html = this.html.replace(`<a target="_self" rel="noopener noreferrer nofollow" href="#${this.articleReferenceIds[i + 1]}"><sup class="reference">[${this.articleReferenceIds[i + 1]}]</sup>`,
              `<a target="_self" rel="noopener noreferrer nofollow" href="#${j + 1}"><sup class="reference">[${j + 1}]</sup>`);
            this.savedreferences.forEach((val,index)=>{
              if(val.id === this.articleReferenceIds[i + 1]){
                this.savedreferences[index].id = j + 1;
              }
            })
          } else {
            let needle = `<a target="_self" rel="noopener noreferrer nofollow" href="#${j}"><sup class="reference">[${j}]</sup>`;
            let lastIndex = this.html.lastIndexOf(needle);
            this.html = this.html.substring(0, lastIndex) + `<a target="_self" rel="noopener noreferrer nofollow" href="#${j + 1}"><sup class="reference">[${j + 1}]</sup>` +this.html.substring(lastIndex + needle.length);
              this.savedreferences[j-1].id = this.savedreferences[j-1].id+1;
            
          }
        }
     },
     addReferenceAtFirstPosition(){
      let count=0;
      this.articleReferenceIds.forEach((val, index)=>{
        if(index==0){
          this.html = this.html.replace(`<a target="_self" rel="noopener noreferrer nofollow" href="#${this.articleReferenceIds[0]}"><sup class="reference">[${this.articleReferenceIds[0]}]</sup>`,
            `<a target="_self" rel="noopener noreferrer nofollow" href="#${1}"><sup class="reference">[${1}]</sup>`);
          this.savedreferences[this.savedreferences.length-1].id  = 1;
      
        }
        else{
          let needle = `<a target="_self" rel="noopener noreferrer nofollow" href="#${this.articleReferenceIds[index]}"><sup class="reference">[${this.articleReferenceIds[index]}]</sup>`;
          let lastIndex = this.html.lastIndexOf(needle);
          this.html = this.html.substring(0, lastIndex) + `<a target="_self" rel="noopener noreferrer nofollow" href="#${this.articleReferenceIds[index] + 1}"><sup class="reference">[${this.articleReferenceIds[index] + 1}]</sup>` +this.html.substring(lastIndex + needle.length);
          this.savedreferences[count].id = this.savedreferences[count].id+1;
        count++;
      }
      })
     },
     publishArticle(err){
      this.err = err;
      if(this.editor && !(err.title)){
        // this.editor.commands.setContent("");
        this.editor.commands.focus();
      }
    },
    addImage(imageUrl) {
      this.editor.chain().focus().setImage({ src: imageUrl }).run();
      this.closeBubbleMenu();
    },
    addVideo(videoUrl) {
      const video = this.getEmbedUrl(videoUrl);
      if(video){
        this.editor.chain().focus().setExternalVideo({ src: video }).run();
      }
    },
    getEmbedUrl(videoUrl) {
      if (videoUrl.includes("embed")) {
        return videoUrl;
      }
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      var match = videoUrl.match(regExp);
      if (match && match[2].length === 11) {
        return `https://www.youtube.com/embed/${match[2]}`;
      }
      regExp = "vimeo\\.com/(?:.*#|.*/videos/)?([0-9]+)";
      match = videoUrl.match(regExp);
      if (match) {
        var videoid = videoUrl.split("/")[videoUrl.split("/").length - 1];
        return `https://player.vimeo.com/video/${videoid}`;
      } else {
        this.message = {
          title: "Invalid Url",
          desc: "You have entered an invalid url. Please enter a valid youtube or vimeo url",
          type: 'failure'
        }
        this.showMessageModal = true;
        return false;
      }
    },
    showInput() {
      this.showOptions = false;
      this.visibleInput = true;
      this.url = this.editor.getAttributes("link").href;
    },
    closeBubbleMenu() {
      this.visibleInput = false;
      this.showOptions = true;
    },
    setLink() {
      // cancelled
      if (this.url === null || typeof this.url == "undefined") {
        this.visibleInput = false;
        this.showOptions = true;
        return;
      }
      // empty
      if (this.url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
        this.visibleInput = false;
        this.showOptions = true;
        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: this.url })
        .run();
      this.url = null;
      this.visibleInput = false;
      this.showOptions = true;
    },
    addHr() {
      this.editor.chain().focus().setHorizontalRule().run();
    },
    addcode() {
      this.editor.chain().focus().toggleCodeBlock().run();
    },
    showAddReferenceBubble() {
      var sel = document.getSelection();
      this.pos = sel.toString().length;
      //if(sel. anchorNode != undefined) sel. collapseToEnd();
      // console.log(this.pos);
      this.showOptions = false;
      this.visibleInput = false;
      this.referenceInputVisible = true;
    },
    addReference(ref) {
      this.referenceInputVisible = false;
      let html = this.editor.getHTML();
      let id =
        (
          html.match(
            /<a target="_self" rel="noopener noreferrer nofollow" href="#/g
          ) || []
        ).length + 1;
      const endpos = this.editor.state.selection.to;
      this.$store.dispatch("editor/addReference", { id: id, content: ref.content });
      this.editor
        .chain()
        .insertContentAt(
          endpos,
          `<sup><a class="footnote" href = '#${id}' target='_self'>[${id}]</a></sup><span>&nbsp;</span>`
        )
        .focus()
        .run();
    },
  },
};
</script>
<style lang="scss">
.title {
  .input-text {
    font-size: 3rem;
    font-family: $font-primary;
    font-family: $font-primary-bold;
    color: $color-black;
    border: none;
    caret-color: $color-primary;
    padding: 1.2rem 1.2rem 1.2rem 0;
    width: 100%;

    // @include respond(phone-x-small) {
    //   height: 40rem;
    // }
  }

  .input-text:focus-visible {
    outline: none;
  }
  .input-text::placeholder {
    color: #cccccc;
    font-family: $font-primary;
  }
}
.description {
    div {
      .ProseMirror {
        p {
    color: black;
    font-family: $font-primary;
    font-size: 1.86rem;
  }
  a {
    color: $color-primary;
    text-decoration: underline;
    &:hover {
      color: $color-primary;
    }
  }
  img {
    width: 100%;
    margin: 4rem 0;
  }
  .ProseMirror {
    > * + * {
      margin-top: 0.75em;
    }
  }
  .ProseMirror {
    padding: 4rem 0;
  }
  .ProseMirror-focused {
    border: none;
    outline: none;
  }
  .ProseMirror:first-child {
    font-size: 1.9rem;
    font-family: $font-primary;
  }
  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: $color-black;
    opacity: 0.2;
    pointer-events: none;
    height: 0;
    font-size: 1.9rem;
    font-family: $font-primary;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 3px solid rgba(#0d0d0d, 0.1);
  }

  .bubble-menu {
    background-color: #373737;
    &::after {
      content: "";
      width: 2.5rem;
      height: 2rem;
      background-color: #373737;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      clip-path: polygon(100% 0, 0 0, 50% 100%);
    }
    .options,
    .input-url {
      padding: 1rem;
    }
    .icon {
      color: #999;
      margin: 0.75rem 1rem;
      font-size: 1.6rem;
      cursor: pointer;
    }
    .icon-clip {
      display: inline-block;
      transform: scale(1.5, 1.3);
    }
    .icon-double_quotes {
      display: inline-block;
      transform: scale(1.5, 1.3);
    }
    .icon-add-reference {
      width: 10%;
      filter: invert(67%) sepia(0%) saturate(1%) hue-rotate(161deg)
        brightness(94%) contrast(79%);
    }

    .input-url {
      input {
        background-color: #373737;
        border: none;
        outline: none;
        color: lightgray;
      }
    }
    .reference {
      &__input {
        border: none;
        outline: none;
        width: 20rem;
        &:focus-visible,
        &:focus,
        &:active {
          border: none;
          outline: none;
        }
      }
    }
  }
  .bubble-menu.light-bg {
    background-color: $color-white;
    box-shadow: 10px 10px 3rem $color-dark-grey-2;
    transform: translateX(30%);
    // width: 35rem;
    // textarea{
    //     min-height: 10rem;
    //     width: 35rem;
    //     padding: 1rem;

    // }
    &::after {
      background-color: $color-white;
      left: 0;
      width: 2rem;
      height: 3rem;
      transform: translateX(0);
      clip-path: polygon(100% 0, 0 0, 0 100%);
    }
  }
      }
    }
}
//Iframe
.video-wrapper {
  height: 43rem;
  iframe {
    width: 100%;
    height: 100%;
  }
}

//Code Block
pre {
  background: #0d0d0d;
  color: $color-white;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 4rem;
  position: relative;
  overflow: visible;
  code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 1.6rem;
  }
}

.hljs-comment,
.hljs-quote {
  color: #616161;
}

.hljs-variable,
.hljs-template-variable,
.hljs-attribute,
.hljs-tag,
.hljs-name,
.hljs-regexp,
.hljs-link,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
  color: #f98181;
}

.hljs-number,
.hljs-meta,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params {
  color: #fbbc88;
}

.hljs-string,
.hljs-symbol,
.hljs-bullet {
  color: #b9f18d;
}

.hljs-title,
.hljs-section {
  color: #faf594;
}

.hljs-keyword,
.hljs-selector-tag {
  color: #70cff8;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: 700;
}
//hr
.editor-hr {
  width: 8px;
  margin: 8rem auto;
  border-radius: 100%;
  height: 8px;
  background-color: $color-black;
  position: relative;
  &::before,
  &::after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    background-color: $color-black;
    top: -10%;
    display: inline-block;
  }
  &::before {
    left: -3rem;
  }
  &::after {
    left: 3rem;
  }
}
.error{
  text-align: left;
}
</style>